// ~~~ CONTENTFUL PAGE ~~~
/* eslint-disable */

import React from 'react'
import Helmet from 'react-helmet'
import Layout from 'components/Layout'
import KnowledgeTopic from 'components/Contentful/KnowledgeTopic'
import SideNavigation from 'components/SideNavigation'
import PageNavigation from 'components/PageNavigation'

const Page = props => {
  return (
    <Layout location={props.location}>
      <Helmet
        title="Die Wärmepumpe einfach erklärt"
        meta={[
          { name: 'description', content: 'Die Wärmepumpe einfach erklärt. Wie funktioniert eine Wärmepumpe? Was sind deren Vor- und Nachteile im Vergleich mit anderen Heizsystemen? Welche Typen von Wärmepumpen gibt es? Hier finden Sie die wichtigsten Informationen auf all Ihre Fragen. ' },
          { name: 'keywords', content: '' },
        ]}
      >
        
      </Helmet>
      
      <PageNavigation location={props.location} links={[
    {
        "type": "link",
        "id": "3P9prSaMlzdADoC8f1gPT7",
        "updatedAt": "2021-02-11T13:38:26.956Z",
        "label": "Fördergelder",
        "page": {
            "slug": "foerdergelder"
        }
    },
    {
        "type": "link",
        "id": "1xligmPvFk1S9Z6xdQzjAE",
        "updatedAt": "2020-03-27T13:38:18.329Z",
        "label": "Die Wärmepumpe einfach erklärt",
        "page": {
            "slug": "Die-Waermepumpe-einfach-erklaert"
        }
    }
]} /><div className='section__nav section--padded'><SideNavigation location={props.location} links={[
    {
        "type": "link",
        "id": "3YsoMo1NVNMD9R5EUCcPZY",
        "updatedAt": "2020-03-27T12:09:36.532Z",
        "label": "Eine Wärmepumpe ist",
        "page": {
            "slug": "Die-Waermepumpe-einfach-erklaert/eine-waermepumpe-ist"
        }
    },
    {
        "type": "link",
        "id": "4eeVTOLecGuLIeZOm4LSQ",
        "updatedAt": "2020-03-27T14:19:22.162Z",
        "label": "Funktionsweise Wärmepumpe",
        "page": {
            "slug": "Die-Waermepumpe-einfach-erklaert/funktionsweise-einer-waermepumpe"
        }
    },
    {
        "type": "link",
        "id": "mlApPFnIU8bx7k8iiIeJs",
        "updatedAt": "2020-03-27T14:53:37.132Z",
        "label": "Häufig gestellte Fragen - FAQ",
        "page": {
            "slug": "Die-Waermepumpe-einfach-erklaert/haeufig-gestellte-fragen-faq"
        }
    }
]} /><div
          className='bg__flex--white'
          key='77KOB2HMCiWjoOUNIu3tdS-1nyiV0UJXumOp0VZUlvsMV'
          id=''
        >
          <KnowledgeTopic
            {...{
    "type": "knowledgeTopic",
    "id": "1nyiV0UJXumOp0VZUlvsMV",
    "updatedAt": "2020-03-27T10:37:16.424Z",
    "title": "Die Wärmepumpe einfach erklärt",
    "featuredArticles": {
        "type": "knowledgeHighlight",
        "id": "TkhvKkPe6JtRjj3PACzEi",
        "updatedAt": "2020-06-22T07:40:25.814Z",
        "title": "Artikel zum Thema Wärmepumpe einfach erklärt",
        "knowledgeArticles": [
            {
                "type": "page",
                "id": "4dw60pEJdZy4dp7KMwTZna",
                "updatedAt": "2020-04-03T12:50:46.524Z",
                "name": "Eine Wärmepumpe ist",
                "slug": "Die-Waermepumpe-einfach-erklaert/eine-waermepumpe-ist",
                "hideInProduction": false,
                "components": [
                    {
                        "type": "knowledgeArticle",
                        "id": "1lMqejNefecSqtLyu3qJ7n",
                        "updatedAt": "2020-04-06T06:58:34.120Z",
                        "title": "Eine Wärmepumpe ist ...",
                        "keyword": "Eine Wärmepumpe ist",
                        "articleType": "Infoartikel",
                        "description": "Ein alternatives Heizsystem, welches heutzutage sehr gefragt ist. Sie ist nicht nur kostengünstig im Betrieb, sondern auch smart und einfach.",
                        "content": [
                            {
                                "type": "infoarticle",
                                "id": "2H8GfhLe9IRQoH1Yiqr9j9",
                                "updatedAt": "2020-03-27T10:34:20.077Z",
                                "title": "Eine Wärmepumpe ist",
                                "text": "<h3>Intelligent</h3>\n<p>Wussten Sie dass eine Wärmepumpe zwei Drittel der benötigten Energie aus der Umwelt gewinnt? Nur ein Drittel ist elektrische Energie. Den benötigten Strom können Sie ausserdem mit einer Solaranlage bereitstellen. Somit haben Sie 100% der Wärme aus der Umwelt.</p>\n<h3>Einfach</h3>\n<p>Einfache und standardisierte Systeme garantieren eine gut funktionierende Wärmepumpe. Durch eine richtige Dimensionierung und Planung vermeiden wir die üblichen Fehler. Genau dies ist unsere Philosophie, je einfacher und standardisierter das System ist, desto effizienter und wartungsarmer ist der Betrieb. Ganz einfach.</p>\n<h3>Sicher und sauber</h3>\n<p>Kein Kaminfeger, keine Rückstände, keine Abgase. Wärmepumpen nutzen Wärme aus der Umwelt, zum Beispiel aus der Umgebungsluft. Zu keiner Zeit ist Brennstoff im Haus und Luft hat es immer genug.</p>\n"
                            }
                        ],
                        "topic": {
                            "type": "link",
                            "id": "1xligmPvFk1S9Z6xdQzjAE",
                            "updatedAt": "2020-03-27T13:38:18.329Z",
                            "label": "Die Wärmepumpe einfach erklärt",
                            "page": {
                                "slug": "Die-Waermepumpe-einfach-erklaert"
                            }
                        },
                        "previewImage": {
                            "description": "Wärmepumpe NIBE F2120 aussenaufgestellt",
                            "contentType": "image/png",
                            "url": "//images.ctfassets.net/wbm1kpreb3m8/9i4Ra5dNE46w2uaA2GmI0/b99ceeea7c545ba23cb18e18be7d67c0/NIBE_F2120_8kW_perspekt_ohne_Schatten.png"
                        }
                    }
                ],
                "title": "Eine Wärmepumpe ist",
                "navigation": {
                    "type": "navigation",
                    "id": "5SWhAHJYNxaIBZb6QUra3M",
                    "updatedAt": "2020-04-03T12:39:06.368Z",
                    "name": "Wissensnavigation",
                    "links": [
                        {
                            "type": "link",
                            "id": "3P9prSaMlzdADoC8f1gPT7",
                            "updatedAt": "2021-02-11T13:38:26.956Z",
                            "label": "Fördergelder",
                            "page": {
                                "slug": "foerdergelder"
                            }
                        },
                        {
                            "type": "link",
                            "id": "1xligmPvFk1S9Z6xdQzjAE",
                            "updatedAt": "2020-03-27T13:38:18.329Z",
                            "label": "Die Wärmepumpe einfach erklärt",
                            "page": {
                                "slug": "Die-Waermepumpe-einfach-erklaert"
                            }
                        }
                    ]
                },
                "sideNavigation": {
                    "type": "navigation",
                    "id": "61Y85Ap9bkVroVfyXY3JAr",
                    "updatedAt": "2020-03-27T14:53:43.374Z",
                    "name": "Seitennavigation zu Die Wärmepumpe einfach erklärt",
                    "links": [
                        {
                            "type": "link",
                            "id": "3YsoMo1NVNMD9R5EUCcPZY",
                            "updatedAt": "2020-03-27T12:09:36.532Z",
                            "label": "Eine Wärmepumpe ist",
                            "page": {
                                "slug": "eine-waermepumpe-ist"
                            }
                        },
                        {
                            "type": "link",
                            "id": "4eeVTOLecGuLIeZOm4LSQ",
                            "updatedAt": "2020-03-27T14:19:22.162Z",
                            "label": "Funktionsweise Wärmepumpe",
                            "page": {
                                "slug": "funktionsweise-einer-waermepumpe"
                            }
                        },
                        {
                            "type": "link",
                            "id": "mlApPFnIU8bx7k8iiIeJs",
                            "updatedAt": "2020-03-27T14:53:37.132Z",
                            "label": "Häufig gestellte Fragen - FAQ",
                            "page": {
                                "slug": "haeufig-gestellte-fragen-faq"
                            }
                        }
                    ]
                },
                "homepage": false,
                "metaDescription": "Die Wärmepumpe ist nicht nur kostengünstig im Betrieb, sondern auch intelligent, einfach sowie sicher und sauber. Die Heizungsmacher AG erklärt Ihnen weshalb.",
                "metaKeywords": "Wärmepumpe Erklärung Einfach Beratung Heizungsmacher AG"
            },
            {
                "type": "page",
                "id": "2HFzip4XAy6gBVzBT7Tn1X",
                "updatedAt": "2020-04-03T13:09:44.938Z",
                "name": "Funktionsweise einer Wärmepumpe",
                "slug": "Die-Waermepumpe-einfach-erklaert/funktionsweise-einer-waermepumpe",
                "hideInProduction": false,
                "components": [
                    {
                        "type": "knowledgeArticle",
                        "id": "4kx53i0XQEB0sh0Mpdjr0t",
                        "updatedAt": "2020-04-06T14:07:08.986Z",
                        "title": "Funktionsweise  einer Wärmepumpe",
                        "keyword": "Funktionsweise",
                        "articleType": "Anleitung",
                        "description": "Die Wärmepumpe funktioniert wie ein Kühlschrank – nur umgekehrt. Sie entzieht Energie aus der Umgebung (z.B. Luft) und gibt sie an das Haus ab.",
                        "content": [
                            {
                                "type": "howToStep",
                                "id": "5Jcq3sCzdrWmgp020xDaQD",
                                "updatedAt": "2021-02-11T08:37:05.666Z",
                                "title": "Verdunstung",
                                "description": "<p><strong>Das Kältemittel im Verdampfer nimmt Wärmeenergie aus der Umgebung auf,</strong> erwärmt sich und ändert seinen Aggregatzustand von flüssig (kalt) zu gasförmig (warm). Dieser Prozess ist mit dem Verdampfen von Wasser vergleichbar, mit dem Unterschied, dass das Temperaturniveau deutlich geringer ist.</p>\n",
                                "image": {
                                    "description": "Grafik zur Erklärung der Funktionsweise einer Wärmepumpe",
                                    "contentType": "image/png",
                                    "url": "//images.ctfassets.net/wbm1kpreb3m8/6DbT7ZXZduEwUuI24qISaY/28d1b37aa90e98863911491caa01c5ca/Referenzbild---wie-funktioniert-eine-w__rmepumpe.png"
                                }
                            },
                            {
                                "type": "howToStep",
                                "id": "19gQTrLiJPk2zAVjwL2rKv",
                                "updatedAt": "2021-02-11T08:37:21.806Z",
                                "title": "Verdichtung",
                                "description": "<p>Im nächsten Schritt verdichtet der elektrische Kompressor das gasförmige Kältemittel. <strong>Durch den Kompressionsvorgang erwärmt sich das Kältemittel,</strong> bis das im Heizsystem erforderliche Temperaturniveau erreicht wird.</p>\n"
                            },
                            {
                                "type": "howToStep",
                                "id": "74zR3L6hudYWgh1bXWuSU6",
                                "updatedAt": "2021-02-11T08:37:40.630Z",
                                "title": "Erhitzung",
                                "description": "<p><strong>Im Wärmetauscher übergibt das Kältemittel seine Wärmeenergie an das Wasser im Heizungskreislauf des Gebäudes.</strong> Auf diese Weise gelingt es, die aus der Umgebung gewonnene Energie schliesslich zum Heizen oder zur Warmwasserbereitung zu verwenden. Bei der Wärmeübertragung wird dem Kältemittel Wärme entzogen und es geht vom gasförmigen wieder in den flüssigen Zustand über – es kondensiert.</p>\n"
                            },
                            {
                                "type": "howToStep",
                                "id": "1KVoZp2vxColqwUojLIDOm",
                                "updatedAt": "2021-02-11T08:38:11.141Z",
                                "title": "Abkühlung und Verflüssigung",
                                "description": "<p>Bevor der Kreislauf im Verdampfer erneut beginnt, fliesst das Kältemittel durch das Expansionsventil. <strong>Dabei sinken Druck und Temperatur auf den Ausgangszustand und der Heizkreislauf beginnt wieder von vorne.</strong></p>\n"
                            }
                        ],
                        "topic": {
                            "type": "link",
                            "id": "1xligmPvFk1S9Z6xdQzjAE",
                            "updatedAt": "2020-03-27T13:38:18.329Z",
                            "label": "Die Wärmepumpe einfach erklärt",
                            "page": {
                                "slug": "Die-Waermepumpe-einfach-erklaert"
                            }
                        },
                        "previewImage": {
                            "description": "Grafik zur Erklärung der Funktionsweise einer Wärmepumpe",
                            "contentType": "image/png",
                            "url": "//images.ctfassets.net/wbm1kpreb3m8/6DbT7ZXZduEwUuI24qISaY/28d1b37aa90e98863911491caa01c5ca/Referenzbild---wie-funktioniert-eine-w__rmepumpe.png"
                        }
                    }
                ],
                "title": "Funktionsweise Wärmepumpe",
                "navigation": {
                    "type": "navigation",
                    "id": "5SWhAHJYNxaIBZb6QUra3M",
                    "updatedAt": "2020-04-03T12:39:06.368Z",
                    "name": "Wissensnavigation",
                    "links": [
                        {
                            "type": "link",
                            "id": "3P9prSaMlzdADoC8f1gPT7",
                            "updatedAt": "2021-02-11T13:38:26.956Z",
                            "label": "Fördergelder",
                            "page": {
                                "slug": "foerdergelder"
                            }
                        },
                        {
                            "type": "link",
                            "id": "1xligmPvFk1S9Z6xdQzjAE",
                            "updatedAt": "2020-03-27T13:38:18.329Z",
                            "label": "Die Wärmepumpe einfach erklärt",
                            "page": {
                                "slug": "Die-Waermepumpe-einfach-erklaert"
                            }
                        }
                    ]
                },
                "sideNavigation": {
                    "type": "navigation",
                    "id": "61Y85Ap9bkVroVfyXY3JAr",
                    "updatedAt": "2020-03-27T14:53:43.374Z",
                    "name": "Seitennavigation zu Die Wärmepumpe einfach erklärt",
                    "links": [
                        {
                            "type": "link",
                            "id": "3YsoMo1NVNMD9R5EUCcPZY",
                            "updatedAt": "2020-03-27T12:09:36.532Z",
                            "label": "Eine Wärmepumpe ist",
                            "page": {
                                "slug": "eine-waermepumpe-ist"
                            }
                        },
                        {
                            "type": "link",
                            "id": "4eeVTOLecGuLIeZOm4LSQ",
                            "updatedAt": "2020-03-27T14:19:22.162Z",
                            "label": "Funktionsweise Wärmepumpe",
                            "page": {
                                "slug": "funktionsweise-einer-waermepumpe"
                            }
                        },
                        {
                            "type": "link",
                            "id": "mlApPFnIU8bx7k8iiIeJs",
                            "updatedAt": "2020-03-27T14:53:37.132Z",
                            "label": "Häufig gestellte Fragen - FAQ",
                            "page": {
                                "slug": "haeufig-gestellte-fragen-faq"
                            }
                        }
                    ]
                },
                "homepage": false,
                "metaDescription": "Funktionsweise einer Wärmepumpe. Die Wärmepumpe funktioniert wie ein Kühlschrank – nur umgekehrt. Sie entzieht Energie aus der Umgebung (z.B. Luft) und gibt sie an das Haus ab. Erfahren Sie mehr - www.heizungsmacher.ch."
            }
        ],
        "isVertical": true
    },
    "content": "<p>Wie funktioniert eine Wärmepumpe? Was sind deren Vor- und Nachteile im Vergleich mit anderen Heizsystemen? Welche Typen von Wärmepumpen gibt es? Fragen über Fragen.\nHier finden Sie die wichtigsten Informationen auf all Ihre Fragen. Und sollte doch eine Frage unbeantwortet bleiben, helfen wir Ihnen gerne persönlich im Rahmen einer Beratung weiter.</p>\n"
}} location={props.location}
          />
        </div></div>
    </Layout>
  )
}

export default Page
