import React from 'react'
import PropTypes from 'prop-types'
import Link from 'components/Link'
import Image from 'components/Image'
import { knowledgeArticlePropType } from 'lib/prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelope, faPhone } from '@fortawesome/free-solid-svg-icons'
import { phoneNumber } from 'lib/phone-number'

const defaultImageUrl = '/knowledge/knowledge-default-img'

const KnowledgeTopic = props => {
  const { featuredArticles } = props
  return (
    <div>
      <h2 className="headline headline--left">{props.title}</h2>
      <div
        dangerouslySetInnerHTML={{ __html: props.content }}
        className="article__description"
      />
      <hr className="seperator" />
      {featuredArticles ? (
        <div>
          <h2>{featuredArticles.title}</h2>
          <div
            className={
              featuredArticles.isVertical
                ? 'article__cards--full'
                : 'article__cards'
            }
          >
            {featuredArticles.knowledgeArticles.map(articlePage => {
              const article = articlePage.components[0]
              return (
                <Link
                  key={articlePage.slug}
                  className="article__card hover--shadow"
                  to={'/' + articlePage.slug}
                >
                  <div className="article__card--img">
                    {article.previewImage ? (
                      <img src={article.previewImage.url} />
                    ) : (
                      <Image name={defaultImageUrl} />
                    )}
                  </div>
                  <div className="article__card--content">
                    <h3 className="article__card--title">{article.title}</h3>
                    <div className="article__card--description">
                      {article.description}
                    </div>
                  </div>
                </Link>
              )
            })}
          </div>
        </div>
      ) : null}

      <h2 className="mt0">Kontaktieren Sie uns</h2>
      <p>
        Für eine persönliche Unterstützung vereinbaren Sie direkt einen
        Beratungstermin und rufen Sie uns an oder schreiben uns eine E-Mail:
      </p>

      <div className="contact__links">
        <a
          href={`tel:+41${phoneNumber.substring(1).replace(/ /g, '')}`}
          className="contact__link contact__link--btn margin--v1"
        >
          <FontAwesomeIcon icon={faPhone} className="link__icon--left" />
          <span>{phoneNumber}</span>
        </a>
        <a
          href="mailto:info@heizungsmacher.ch"
          className="contact__link contact__link--btn margin--v1"
        >
          <FontAwesomeIcon icon={faEnvelope} className="link__icon--left" />
          <span>info@heizungsmacher.ch</span>
        </a>
      </div>
    </div>
  )
}

KnowledgeTopic.propTypes = {
  title: PropTypes.string,
  content: PropTypes.string,
  featuredArticles: PropTypes.shape({
    title: PropTypes.string,
    knowledgeArticles: PropTypes.arrayOf(knowledgeArticlePropType),
    isVertical: PropTypes.bool,
  }),
}

export default KnowledgeTopic
